import React from 'react'

import Layout from '../components/layout'
import HomePicture from '../components/HomePicture';
import SEO from '../components/SEO';

const IndexPage = () => (
  <Layout>
    <SEO title="Home"/>
    <HomePicture />
  </Layout>
)

export default IndexPage
