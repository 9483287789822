import React from 'react'
import { Fade } from 'react-slideshow-image';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image';

import css from './HomePicture.module.css';


export const homeImage = graphql`
  fragment homeImage on File {
    childImageSharp {
      fixed(width: 410, height: 547, cropFocus: CENTER ) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      atJoeAllen: file(relativePath: { regex: "/homeImages/at-joe-allen.jpg/" }) {...homeImage}
      atWhidbey: file(relativePath: { regex: "/homeImages/will-and-jesse.jpg/" }) {...homeImage}
    }
  `);

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
  };

  return (
    <div className={css.homePicture}>
      <div className={css.picture}>
        <Fade {...properties}>
          <Img
            alt="Will and Jesse, looking adorable at The Inn at Langley, Whidbey Island, Washington." 
            fixed={data.atWhidbey.childImageSharp.fixed}
          />
          <Img
            alt="Will and Jesse, looking adorable at the bar at Joe Allen, New York City." 
            fixed={data.atJoeAllen.childImageSharp.fixed}
          />
        </Fade>
      </div>
      <div className={css.textContainer}>
        <div className={css.text}>
          <h2 className={css.homeline}>
            We’re getting married
          </h2>
          <h2 className={css.homeline}>
            September 21, 2019
          </h2>
          <h2 className={css.homeline}>
            The Deer Mountain Inn<br/>
            Tannersville, New York
          </h2>
        </div>
        <div className={css.shuttles}>
          For day-of shuttle info, see <a href="/accommodations">Accommodations</a>.
        </div>
      </div>
    </div>
  );
}
